import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3', // Keep the blue color as primary
    },
    secondary: {
      main: '#4CAF50', // Change this to the green color from the gradient
    },
  },
  typography: {
    fontFamily: 'Nunito, Arial, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          font-family: 'Nunito', Arial, sans-serif;
        }
      `,
    },
  },
});