import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import Layout from '../components/Layout.tsx';

const Contact = () => {
  return (
    <Layout>
      <Container maxWidth="md">
        <Box my={4}>
          <Typography variant="h2" component="h1" gutterBottom sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            textAlign: 'center',
            mb: 3,
          }}>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            We'd love to hear from you! Whether you have questions about our services, need support, or want to provide feedback, please don't hesitate to reach out.
          </Typography>
          <Typography variant="body1" paragraph>
            Contact information and a contact form will be added here soon.
          </Typography>
        </Box>
      </Container>
    </Layout>
  );
};

export default Contact;