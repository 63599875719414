import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import Layout from '../components/Layout.tsx';

const Privacy = () => {
  return (
    <Layout>
      <Container maxWidth="md">
        <Box my={4}>
          <Typography variant="h2" component="h1" gutterBottom sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            textAlign: 'center',
            mb: 3,
          }}>
            Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            At WhichLLM, we are committed to protecting your privacy. This policy outlines how we collect, use, and safeguard your personal information:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 4 }}>
            <li>We collect information you provide directly to us, such as search queries.</li>
            <li>We store your search queries to improve our search engine and provide better recommendations.</li>
            <li>We do not sell or share your personal information with third parties for marketing purposes.</li>
            <li>We use industry-standard security measures to protect your data.</li>
            <li>You can contact us to request access to, correction of, or deletion of your personal information.</li>
          </Typography>
          <Typography variant="body1" paragraph mt={2}>
            By using WhichLLM, you consent to the collection and use of information as described in this policy. We may update this policy from time to time, and we encourage you to review it periodically.
          </Typography>
        </Box>
      </Container>
    </Layout>
  );
};

export default Privacy;