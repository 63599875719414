import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, InputAdornment, Paper, CircularProgress, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
// import TuneIcon from '@mui/icons-material/Tune';

interface SearchComponentProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (e: React.KeyboardEvent) => void;
  onSubmit: () => void;
  isLoading: boolean;
}

const SearchComponent: React.FC<SearchComponentProps> = ({
  value,
  onChange,
  onKeyPress,
  onSubmit,
  isLoading
}) => {
  const [inputHeight, setInputHeight] = useState(56);
  const textFieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textFieldRef.current) {
      const scrollHeight = textFieldRef.current.querySelector('textarea')?.scrollHeight;
      if (scrollHeight) {
        setInputHeight(Math.min(Math.max(scrollHeight, 56), 96)); // Min 56px, Max 96px (3 lines)
      }
    }
  }, [value]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  const handleClear = () => {
    onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <Box mt={4} position="relative">
      <Paper 
        elevation={3} 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          p: '4px 8px',
          borderRadius: '28px',
          transition: 'all 0.3s ease-in-out',
          height: `${inputHeight}px`,
          background: 'linear-gradient(135deg, #4CAF50 0%, #2196F3 100%)',
          overflow: 'hidden', 
          pr: 1, 
        }}
      >
        <TextField
          fullWidth
          multiline
          maxRows={3}
          placeholder="Describe your LLM needs..."
          name="describeNeed"
          variant="standard"
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          ref={textFieldRef}
          disabled={isLoading}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                {isLoading ? (
                  <CircularProgress size={28} color="inherit" />
                ) : (
                  <SearchIcon sx={{ fontSize: 28, color: 'white' }} />
                )}
              </InputAdornment>
            ),
            endAdornment: value && !isLoading && (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClear}
                  edge="end"
                  sx={{ 
                    color: 'white',
                    mr: -1, 
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ 
            ml: 2,
            flex: 1,
            '& .MuiInputBase-root': {
              fontSize: '1.2rem',
              color: 'white',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '&.Mui-focused': {
                backgroundColor: 'transparent',
              },
              overflow: 'hidden',
              pr: 1,
            },
            '& .MuiInputBase-input': {
              py: 1.5,
              '&::placeholder': {
                color: 'rgba(255, 255, 255, 0.8)',
                opacity: 1,
              },
              '&::selection': {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
              },
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'pre-wrap',
              cursor: isLoading ? 'not-allowed' : 'text',
            },
          }}
        />
      </Paper>
    </Box>
  );
};

export default SearchComponent;