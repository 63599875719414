export const questions = [
  {
    label: 'Domain and Content Type',
    fields: [
      { label: 'Type of Content', name: 'contentType' },
      { label: 'Specialized Knowledge Required', name: 'specializedKnowledge' },
    ],
  },
  {
    label: 'Output Quality',
    fields: [
      { label: 'Quality and Accuracy Level', name: 'qualityAccuracy' },
      { label: 'Creativity vs. Factual Accuracy', name: 'creativityAccuracy' },
    ],
  },
  {
    label: 'Customization and Control',
    fields: [
      { label: 'Need for Fine-Tuning', name: 'fineTuning' },
      { label: 'Control Over Model Behavior', name: 'modelControl' },
    ],
  },
  {
    label: 'Integration and Deployment',
    fields: [
      { label: 'Integration Plan', name: 'integrationPlan' },
      { label: 'Deployment Platforms', name: 'deploymentPlatforms' },
    ],
  },
  {
    label: 'Data Privacy and Security',
    fields: [
      { label: 'Privacy and Security Requirements', name: 'privacySecurity' },
      { label: 'Handling Sensitive Data', name: 'sensitiveData' },
    ],
  },
  {
    label: 'Budget and Cost',
    fields: [
      { label: 'Budget', name: 'budget' },
      { label: 'Cost Model Preference', name: 'costModel' },
    ],
  },
  {
    label: 'Model Size and Performance',
    fields: [
      { label: 'Model Size Preference', name: 'modelSize' },
      { label: 'Response Time Tolerance', name: 'responseTime' },
    ],
  },
  {
    label: 'Language Support',
    fields: [
      { label: 'Languages Needed', name: 'languagesNeeded' },
      { label: 'Critical Language or Dialect', name: 'criticalLanguage' },
    ],
  },
  {
    label: 'User Experience',
    fields: [
      { label: 'Desired User Experience', name: 'userExperience' },
      { label: 'Adaptation to User Preferences', name: 'userPreferences' },
    ],
  },
  {
    label: 'Existing Tools and Workflow',
    fields: [
      { label: 'Existing Tools and APIs', name: 'existingTools' },
      { label: 'Compatibility with Current Stack', name: 'currentStack' },
    ],
  },
  {
    label: 'Ethical Considerations',
    fields: [
      { label: 'Ethical Concerns or Guidelines', name: 'ethicalConcerns' },
      { label: 'Adherence to Ethical Standards', name: 'ethicalStandards' },
    ],
  },
];