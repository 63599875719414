import React from 'react';
import { Box, Button, TextField, Stepper, Step, StepLabel, StepContent } from '@mui/material';

interface ChooseOptionsComponentProps {
  questions: Array<{ label: string; fields: Array<{ label: string; name: string }> }>;
  activeStep: number;
  formData: Record<string, string>;
  handleNext: () => void;
  handleBack: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
}

const ChooseOptionsComponent: React.FC<ChooseOptionsComponentProps> = ({
  questions,
  activeStep,
  formData,
  handleNext,
  handleBack,
  handleChange,
  handleSubmit,
}) => {
  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        {questions.map((question, index) => (
          <Step key={question.label}>
            <StepLabel>{question.label}</StepLabel>
            <StepContent>
              {question.fields.map((field) => (
                <TextField
                  key={field.name}
                  fullWidth
                  label={field.label}
                  name={field.name}
                  margin="normal"
                  value={formData[field.name] || ''}
                  onChange={handleChange}
                />
              ))}
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={index === questions.length - 1 ? handleSubmit : handleNext}
                >
                  {index === questions.length - 1 ? 'Submit' : 'Next'}
                </Button>
                {index > 0 && (
                  <Button
                    onClick={handleBack}
                    style={{ marginLeft: '1rem' }}
                  >
                    Back
                  </Button>
                )}
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default ChooseOptionsComponent;