import React from 'react';
import { ThemeProvider, CssBaseline, Container, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { theme } from '../theme.ts';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box 
        sx={{ 
          minHeight: '100vh',
          background: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Container 
          maxWidth="md" 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            flexGrow: 1,
            py: 4,
          }}
        >
          {children}
        </Container>
        <Box 
          component="footer" 
          sx={{
            py: 1, // Further reduced padding
            px: 2,
            mt: 'auto',
            borderTop: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box mb={1}>
            <Link to="/" style={{ color: 'inherit', margin: '0 8px', textDecoration: 'none' }}>
              Home
            </Link>
            <Link to="/about" style={{ color: 'inherit', margin: '0 8px', textDecoration: 'none' }}>
              About
            </Link>
            <Link to="/privacy" style={{ color: 'inherit', margin: '0 8px', textDecoration: 'none' }}>
              Privacy Policy
            </Link>
            <Link to="/terms" style={{ color: 'inherit', margin: '0 8px', textDecoration: 'none' }}>
              Terms of Use
            </Link>
          </Box>
          <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.8rem' }}>
            © 2024 WhichLLM. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;