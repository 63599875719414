import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, Box, useTheme } from '@mui/material';

interface SuggestedLLM {
  llm_name: string;
  llm_description: string;
  llm_reference_url: string;
  suggestion_reason: string;
}

const SuggestionItemComponent: React.FC<SuggestedLLM & { delay: number }> = ({
  llm_name,
  llm_description,
  llm_reference_url,
  suggestion_reason,
  delay,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  const handleCardClick = () => {
    window.open(llm_reference_url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Card 
      elevation={isHovered ? 4 : 2}
      sx={{ 
        width: '100%', 
        marginBottom: '0.75rem',
        opacity: isVisible ? 1 : 0,
        transform: `translateY(${isVisible ? 0 : '20px'})`,
        transition: `all ${isHovered ? '0.5s' : '0.8s'} cubic-bezier(0.4, 0, 0.2, 1)`,
        background: '#FFFFFF',
        borderRadius: '12px',
        overflow: 'hidden',
        position: 'relative',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        cursor: 'pointer',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '4px',
          background: 'linear-gradient(135deg, #4CAF50 0%, #2196F3 100%)',
        },
        '&:hover': {
          transform: 'translateY(-4px)',
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleCardClick}
    >
      <CardContent 
        ref={contentRef} 
        sx={{ 
          py: 2, 
          px: 3, 
          height: isHovered ? 'auto' : '100px',
          overflow: 'hidden',
          transition: `all ${isHovered ? '0.5s' : '0.8s'} cubic-bezier(0.4, 0, 0.2, 1)`,
        }}
      >
        <Typography 
          variant="h6" 
          gutterBottom 
          sx={{ 
            fontSize: '1.2rem', 
            fontWeight: 'bold',
            background: 'linear-gradient(135deg, #4CAF50 0%, #2196F3 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {llm_name}
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            fontSize: '0.9rem', 
            color: 'text.primary',
            display: '-webkit-box',
            WebkitLineClamp: isHovered ? 'unset' : 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            transition: `all ${isHovered ? '0.5s' : '0.8s'} cubic-bezier(0.4, 0, 0.2, 1)`,
          }}
        >
          {llm_description}
        </Typography>
        <Box 
          sx={{ 
            maxHeight: isHovered ? '1000px' : '0',
            opacity: isHovered ? 1 : 0,
            overflow: 'hidden',
            transition: `all ${isHovered ? '0.5s' : '0.8s'} cubic-bezier(0.4, 0, 0.2, 1)`,
          }}
        >
          <Typography variant="body2" sx={{ 
            fontSize: '0.9rem', 
            mt: 2, 
            color: 'text.primary',
            opacity: isHovered ? 1 : 0,
            transform: `translateY(${isHovered ? 0 : '10px'})`,
            transition: `all ${isHovered ? '0.5s' : '0.8s'} cubic-bezier(0.4, 0, 0.2, 1)`,
          }}>
            <Box component="span" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>Reason:</Box> {suggestion_reason}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SuggestionItemComponent;