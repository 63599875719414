import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import Layout from '../components/Layout.tsx';

const Terms = () => {
  return (
    <Layout>
      <Container maxWidth="md">
        <Box my={4}>
          <Typography variant="h2" component="h1" gutterBottom sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            textAlign: 'center',
            mb: 3,
          }}>
            Terms of Use
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to WhichLLM. By using our service, you agree to comply with and be bound by the following terms and conditions of use:
          </Typography>
          <Typography variant="body1" component="ul" sx={{ pl: 4 }}>
            <li>WhichLLM is provided "as is" without any warranties.</li>
            <li>You agree to use the service responsibly and not to misuse or abuse it.</li>
            <li>We reserve the right to modify or terminate the service at any time.</li>
            <li>You acknowledge that we may store your search queries to improve our service.</li>
            <li>You are responsible for maintaining the confidentiality of your account information.</li>
          </Typography>
          <Typography variant="body1" paragraph mt={2}>
            These terms may be updated from time to time. It is your responsibility to review them periodically.
          </Typography>
        </Box>
      </Container>
    </Layout>
  );
};

export default Terms;