import React, { useState } from 'react';
import { Typography, Box, Paper, List, ListItem, Container, Skeleton } from '@mui/material';
import axios from 'axios';
import SearchComponent from '../components/SearchComponent.tsx';
import ChooseOptionsComponent from '../components/ChooseOptionsComponent.tsx';
import SuggestionItemComponent from '../components/SuggestionItemComponent.tsx';
import { questions } from '../constants.ts';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { IconButton } from '@mui/material';
import Layout from '../components/Layout.tsx';

// Import Nunito font
import '@fontsource/nunito/300.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/500.css';
import '@fontsource/nunito/700.css';

interface SuggestedLLM {
  llm_name: string;
  llm_description: string;
  llm_reference_url: string;
  suggestion_reason: string;
}

// Create an axios instance with a baseURL
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const MainPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [suggestions, setSuggestions] = useState<SuggestedLLM[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [searchId, setSearchId] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await api.post('/find-best-llm/', { formData });
      setSuggestions(response.data.suggestions || []);
    } catch (error) {
      console.error('Error submitting form:', error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    // Remove this line: setSuggestions([]);
  };

  const handleSearchSubmit = async () => {
    setSuggestions([]);
    setIsSearching(true);
    setLoading(true);
    try {
      const response = await api.post('/find-best-llm/', { query: searchQuery });
      setSuggestions(response.data.suggestions || []);
      setSearchId(response.data.id);
      setShowFeedback(true);
    } catch (error) {
      console.error('Error submitting search:', error);
      setSuggestions([]);
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  const toggleAdvancedMode = () => {
    setIsAdvancedMode(!isAdvancedMode);
  };

  const handleFeedback = async (isPositive: boolean) => {
    if (!searchId) {
      console.error('No search ID available');
      return;
    }

    try {
      await api.post('/feedback/', {
        id: searchId,
        feedback: isPositive ? 1 : 2
      });
      console.log(`Feedback submitted successfully: ${isPositive ? 'positive' : 'negative'}`);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
    setShowFeedback(false);
  };

  return (
    <Layout>
      <Container 
        maxWidth="md" 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          flexGrow: 1, // This will make the container take up available space
          py: 4, // Add some padding to top and bottom
        }}
      >
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: '600px',
          }}
        >
          <Box 
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <img 
              src="/logo512.png" 
              alt="WhichLLM Logo" 
              style={{
                width: '120px',
                height: '120px',
                marginBottom: '8px',
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
              }}
            />
            <Typography variant="h2" component="h1" sx={{ 
              fontWeight: 'bold', 
              color: 'primary.main', 
              textAlign: 'center',
              background: 'linear-gradient(135deg, #4CAF50 0%, #2196F3 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: 1,
            }}>
              WhichLLM
            </Typography>
          </Box>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
            Find the best Large Language Model for your needs
          </Typography>
          
          <Box width="100%">
            <SearchComponent
              value={searchQuery}
              onChange={handleSearch}
              onKeyPress={handleSearchKeyPress}
              onSubmit={handleSearchSubmit}
              isLoading={loading}
            />
          </Box>
          
          {isAdvancedMode && (
            <Paper 
              elevation={3} 
              sx={{ 
                p: 3, 
                bgcolor: '#F5F5F5', 
                borderRadius: '0 0 24px 24px',
                mt: '-1px',
                width: '100%'
              }}
            >
              <ChooseOptionsComponent
                questions={questions}
                activeStep={activeStep}
                formData={formData}
                handleNext={handleNext}
                handleBack={handleBack}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </Paper>
          )}
          
          {isSearching && (
            <Box mt={4} width="100%" mb={0}>
              <List>
                {[...Array(3)].map((_, index) => (
                  <ListItem key={index} disablePadding sx={{ mb: 2 }}>
                    <Paper
                      elevation={2}
                      sx={{
                        width: '100%',
                        borderRadius: '12px',
                        overflow: 'hidden',
                        position: 'relative',
                      }}
                    >
                      <Box sx={{ p: 2 }}>
                        <Skeleton 
                          variant="text" 
                          width="40%" 
                          height={28} 
                          sx={{ mb: 1 }} 
                        />
                        <Skeleton 
                          variant="text" 
                          width="100%" 
                          height={20} 
                        />
                        <Skeleton 
                          variant="text" 
                          width="95%" 
                          height={20} 
                        />
                        <Skeleton 
                          variant="text" 
                          width="60%" 
                          height={20} 
                          sx={{ mt: 2 }} 
                        />
                      </Box>
                      <Skeleton 
                        variant="rectangular" 
                        width="100%" 
                        height={4} 
                        sx={{ 
                          position: 'absolute', 
                          top: 0, 
                          left: 0, 
                          background: 'linear-gradient(135deg, #4CAF50 0%, #2196F3 100%)',
                        }} 
                      />
                    </Paper>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          
          {suggestions.length > 0 && (
            <Box mt={4} width="100%" mb={0}>
              <List>
                {suggestions.map((suggestion, index) => (
                  <ListItem key={index} disablePadding sx={{ mb: 2 }}>
                    <SuggestionItemComponent {...suggestion} delay={index * 100} />
                  </ListItem>
                ))}
              </List>
              {showFeedback && (
                <Box 
                  display="flex" 
                  alignItems="center" 
                  justifyContent="center" 
                  mt={2}
                  bgcolor="rgba(255, 255, 255, 0.7)"
                  borderRadius={2}
                  p={1}
                  boxShadow={1}
                >
                  <Typography variant="body2" mr={2}>
                    Were these results helpful?
                  </Typography>
                  <IconButton 
                    onClick={() => handleFeedback(true)} 
                    size="small"
                    color="primary"
                    sx={{ mr: 1 }}
                  >
                    <ThumbUpIcon fontSize="small" />
                  </IconButton>
                  <IconButton 
                    onClick={() => handleFeedback(false)} 
                    size="small"
                    color="secondary"
                  >
                    <ThumbDownIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default MainPage;