import React from 'react';
import { Typography, Box, Container, Link, List, ListItem } from '@mui/material';
import Layout from '../components/Layout.tsx';

const About = () => {
  return (
    <Layout>
      <Container maxWidth="md">
        <Box my={4}>
          <Typography variant="h2" component="h1" gutterBottom sx={{
            fontWeight: 'bold',
            color: 'primary.main',
            textAlign: 'center',
            mb: 3,
          }}>
            About WhichLLM
          </Typography>
          
          <Typography variant="body1" paragraph>
            WhichLLM is a platform designed to help users find the best Large Language Model (LLM) for their specific needs. Our goal is to simplify the process of choosing the right LLM by providing tailored recommendations based on user requirements.
          </Typography>

          <Typography variant="h4" gutterBottom sx={{ mt: 4, mb: 2 }}>
            Why WhichLLM Matters
          </Typography>
          <Typography variant="body1" paragraph>
            The field of artificial intelligence is rapidly evolving, with new LLMs being released frequently. Each model has its own strengths, weaknesses, and specializations. Some excel at general knowledge tasks, while others are fine-tuned for specific domains like medical or coding tasks. Additionally, models vary in size, speed, and resource requirements.
          </Typography>
          <Typography variant="body1" paragraph>
            With such diversity, choosing the right LLM for a particular use case can be overwhelming. WhichLLM addresses this challenge by aggregating data from various leaderboards and providing personalized recommendations. This helps developers, researchers, and businesses make informed decisions, saving time and resources while optimizing their AI applications.
          </Typography>

          <Typography variant="h4" gutterBottom sx={{ mt: 4, mb: 2 }}>
            Leaderboards We Use
          </Typography>
          <Typography variant="body1" paragraph>
            We aggregate data from the following leaderboards to provide comprehensive insights. This list is regularly updated to ensure we're using the most relevant and up-to-date sources:
          </Typography>
          <List>
            <ListItem>
              <Link href="https://huggingface.co/spaces/open-llm-leaderboard/open_llm_leaderboard" target="_blank" rel="noopener noreferrer">
                Open LLM Leaderboard
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://huggingface.co/spaces/ParsBench/leaderboard" target="_blank" rel="noopener noreferrer">
                ParsBench Leaderboard
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://huggingface.co/spaces/lmsys/chatbot-arena-leaderboard" target="_blank" rel="noopener noreferrer">
                Chatbot Arena Leaderboard
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://huggingface.co/spaces/optimum/llm-perf-leaderboard" target="_blank" rel="noopener noreferrer">
                LLM Performance Leaderboard
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://huggingface.co/spaces/bigcode/bigcodebench-leaderboard" target="_blank" rel="noopener noreferrer">
                BigCode Leaderboard
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://huggingface.co/spaces/openlifescienceai/open_medical_llm_leaderboard" target="_blank" rel="noopener noreferrer">
                Medical LLM Leaderboard
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://huggingface.co/spaces/DontPlanToEnd/UGI-Leaderboard" target="_blank" rel="noopener noreferrer">
                UGI Leaderboard
              </Link>
            </ListItem>
          </List>
          <Typography variant="body1" paragraph sx={{ mt: 2 }}>
            Have a suggestion for a new leaderboard we should include? We're always looking to improve our data sources. Please email your suggestions to <Link href="mailto:shahriarshm81@gmail.com">shahriarshm81@gmail.com</Link>.
          </Typography>

          <Typography variant="h4" gutterBottom sx={{ mt: 4, mb: 2 }}>
            Connect with the Author
          </Typography>
          <Typography variant="body1" paragraph>
            Follow the author of WhichLLM on social media:
          </Typography>
          <List>
            <ListItem>
              <Link href="https://twitter.com/shahriarshm" target="_blank" rel="noopener noreferrer">
                Twitter (X)
              </Link>
            </ListItem>
            <ListItem>
              <Link href="https://www.linkedin.com/in/shahriarshm" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </Link>
            </ListItem>
          </List>
        </Box>
      </Container>
    </Layout>
  );
};

export default About;